@font-face {
  font-family: 'Source Sans Pro';
  src: url('../webfonts/SourceSansPro-Regular.woff2') format('woff2'),
  url('../webfonts/SourceSansPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../webfonts/SourceSansPro-Bold.woff2') format('woff2'),
  url('../webfonts/SourceSansPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../webfonts/SourceSansPro-BoldItalic.woff2') format('woff2'),
  url('../webfonts/SourceSansPro-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../webfonts/SourceSansPro-Italic.woff2') format('woff2'),
  url('../webfonts/SourceSansPro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
