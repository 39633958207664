@import "css/sourcesanspro";
@import "css/colors";

$font-family-sans-serif: "Source Sans Pro", "Clarity City", "Roboto Sans", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
$enable-rounded: false;
$enable-shadows: true;

$spacer: .75rem;

$table-cell-padding: .5rem;
$table-cell-padding-sm: .15rem;

$modal-lg: 90%;
$modal-md: 60%;
$modal-sm: 40%;

$link-color: $blue;
/* $component-active-color: $black; */
@import "node_modules/bootstrap/scss/bootstrap";

@import "node_modules/bootstrap-slider/src/sass/bootstrap-slider";


body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #f4f4f4;
}

@-moz-document url-prefix() {
  fieldset {
    display: table-cell;
  }
}

.mdi::before {
  font-size: 20px;
  line-height: 14px;
}

.btn .mdi::before {
  position: relative;
  top: 4px;
}

.btn-xs .mdi::before {
  font-size: 18px;
  top: 3px;
}

.btn-sm .mdi::before {
  font-size: 18px;
  top: 3px;
}

.dropdown-menu .mdi {
  width: 18px;
}

.dropdown-menu .mdi::before {
  position: relative;
  top: 4px;
  left: -8px;
}

.nav .mdi::before {
  position: relative;
  top: 4px;
}

.navbar .navbar-toggle .mdi::before {
  position: relative;
  top: 4px;
  color: #FFF;
}

.breadcrumb .mdi::before {
  position: relative;
  top: 4px;
}

.breadcrumb a:hover {
  text-decoration: none;
}

.breadcrumb a:hover span {
  text-decoration: underline;
}

.alert .mdi::before {
  position: relative;
  top: 4px;
  margin-right: 2px;
}

.input-group-addon .mdi::before {
  position: relative;
  top: 3px;
}

.navbar-brand .mdi::before {
  position: relative;
  top: 2px;
  margin-right: 2px;
}

.list-group-item .mdi::before {
  position: relative;
  top: 3px;
  left: -3px
}

.spinner div {
  width: 20px;
  height: 20px;
  position: absolute;
  left: -20px;
  top: 40px;
  background-color: #333;
  border-radius: 50%;
  animation: move 4s infinite cubic-bezier(.2, .64, .81, .23);
}

.spinner div:nth-child(2) {
  animation-delay: 150ms;
}

.spinner div:nth-child(3) {
  animation-delay: 300ms;
}

.spinner div:nth-child(4) {
  animation-delay: 450ms;
}

@keyframes move {
  0% {
    left: 0%;
  }
  75% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

.loader-container {
  width: 55%;
  margin: 35px auto;
}

.loader {
  height: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #b2e4ff;
}

.loader:before {
  display: block;
  position: absolute;
  content: "";
  left: -200px;
  width: 200px;
  height: 4px;
  background-color: #2980b9;
  animation: loading 2s linear infinite;
}

@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}

ul.cards {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.cards > li {
  display: block;
  float: left;
  margin: 5px;
}

.contentWrapper nav {
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .table-responsive {
    overflow-x: auto;
    overflow-y: auto;
  }
}

@media (min-width: 767px) {
  .table-responsive {
    overflow: inherit !important; /* Sometimes needs !important */
  }
}

.badge {
  margin: 2px;
}

.card table {
  margin: 0;
}

.dropdown-menu .custom-item {
  padding: 3px 1.5rem;
  min-width: 195px;
}

.slider-handle {
  background: #337ab7 !important;
}

.slider-selection {
  background: rgb(204, 240, 255) !important;
}

.slider-horizontal {
  width: 100% !important;
}

.has-cursor-pointer {
  cursor: pointer;
}

app-paginator {
  float: right;
}

input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: #777777;
}

input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #777777;
  opacity: 1;
}

input::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #777777;
  opacity: 1;
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #777777;
}

.minw25 {
  min-width: 25px;
}

.minw50 {
  min-width: 50px;
}

.minw75 {
  min-width: 75px;
}

.minw100 {
  min-width: 100px;
}

.minw150 {
  min-width: 150px;
}

.maxw25 {
  max-width: 25px;
}

.maxw50 {
  max-width: 50px;
}

.maxw75 {
  max-width: 75px;
}

.maxw100 {
  max-width: 100px;
}

.maxw150 {
  max-width: 150px;
}

$widths: 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 99 100;

@each $current-width in $widths {
  .w-#{$current-width} {
    $cw: "#{$current-width}%";
    width: #{$cw} !important;
  }
}

.toast-top-right {
  top: 50px !important;
}

#toast-container > div {
  opacity: 0.95;
}

.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}

.flag-icon:before {
  content: "\00a0";
}

.flag-icon.flag-icon-squared {
  width: 1em;
}


$countries: at be bg es fr hu it lu nl ro si de gb us tr pl sk ba cy ch cz dk ee hr mv mk;

@each $current-country in $countries {
  .flag-icon-#{$current-country} {
    background-image: url("./img/flags/4x3/#{$current-country}.svg");
  }

  .flag-icon-#{$current-country}.flag-icon-squared {
    background-image: url("./img/flags/1x1/#{$current-country}.svg");
  }
}


@import 'node_modules/ngx-toastr/toastr.css';

.toast-container .pin-display {
  margin: 5px;
  text-align: center;
  font-size: 1.4rem;
  font-weight: bold;
}


.modal-backdrop {
  z-index: 1050 !important;
}

.fw-bold,
.text-bold {
  font-weight: bold;
}


span.placeholder {
  background-color: transparent !important;
}
