$yellow: #fef800;
$blue: #1B3E78;
$black: #000;
$white: #fff;

$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;


$grau: #878787;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;


$primary: $blue;
$secondary: $gray-100;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;
$tm-light: lighten($blue, 65%);
$redlight: #ffdddd;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900;
$yiq-text-light: $white;

$colors: (
        "blue": $blue,
        "indigo": $indigo,
        "purple": $purple,
        "pink": $pink,
        "red": $red,
        "orange": $orange,
        "yellow": $yellow,
        "green": $green,
        "teal": $teal,
        "cyan": $cyan,
        "white": $white,
        "gray": $gray-600,
        "gray-dark": $gray-800,
        "redlight": $redlight,
        "gray-light": $gray-200
);


$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "default": $grau,
        "tmlight": $tm-light,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $light,
        "dark": $dark,
        "gray": $gray-600,
        "grey": $gray-600,
        "pink": $pink,
        "redlight":$redlight,
        "gray-light": $gray-200,
);
